import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.scss";
import Home from "./pages/home/Home";
import Price from "./pages/price/Price";
import Contact from "./pages/contact/Contact";
import Header from "./comps/header/Header";
import WithContentEditable from "./comps/content editable/WithContentEditable";
import Footer from "./comps/footer/Footer";
import pricing from "./pages/pricing_Form/Pricing_Form";
import Pricing_Form from "./pages/pricing_Form/Pricing_Form";
import Privacy from "./pages/privacy/Privacy";
import PageToTop from "./pages/pagetotop/PageToTop";
import { useEffect, useState } from "react";
import Thank_You_Page from "./pages/thank_you_page/Thank_You_Page";
import Price_uk from "./pages/pricinguk/Price_uk";
import Term_conditions from "./pages/Terms/Term_conditions";
import Refund from "./pages/Refund/Refund";
import Error from "./pages/notfound/Error";

function App() {
  const [packDetail, setPackDetail] = useState(1);
  const [duration, setDuration] = useState(0);
  const [masterData, setMasterData] = useState([]);




  return (
    <div className="App">
      <BrowserRouter>
        <Header   />
        <PageToTop />

        <Routes>
          <Route path="/" element={<Home />} />
          <>
        
              <Route
                path="/pricing/in"
                element={
                  <Price
                    setPackDetail={setPackDetail}
                    setDuration={setDuration}
                    setMasterData={setMasterData}
                  />
                }
              />

                <Route
                  path="/pricing/uk"
                  element={
                    <Price_uk
                      setPackDetail={setPackDetail}
                      setDuration={setDuration}
                      setMasterData={setMasterData}
                    />
                  }
                />
         
          </>

          <Route path="/contact" element={<Contact />} />
          <Route
            path="/pricing_form"
            element={
              <Pricing_Form
                packDetail={packDetail}
                duration={duration}
                masterData={masterData}
              />
            }
          />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/term_conditions" element={<Term_conditions /> } />
          <Route path="/refund_policy" element={<Refund /> } />
          <Route path="/complete_Payment" element={<Thank_You_Page />} />
          <Route  path="/*" element={<Error /> } />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

// export default WithContentEditable(App);
