import React from 'react'
import "./refund.scss"
const Refund = () => {
  return (
   <div className="refund-parent parent">
    <div className="refund-cont container">
<h4>Refund Policy</h4>
<p>Our Refund Policy was last updated 19 December 2024</p>
<p>Thank you for Choosing us-  <a href='https://ace.axonichealth.com'>https://ace.axonichealth.com</a></p>
<p>If, for any reason, you are not completely satisfied with our services / purchase, we invite you to review our policy on refunds.

The following terms are applicable for any products/services that you purchased/availed with Us.</p>

<h4>Interpretation and Definitions</h4>
<h5>Interpretation</h5>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<h5>Definitions</h5>
<p>For the purposes of this Return and Refund Policy:</p>
<ul><li>“Company” (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Axon Care …..</li>
<li>“Orders”, “Service”  refers to the service offered or availed by yourself on the app/website</li></ul>
<p>“Website” refers to Axonic website, accessible from <a href='https://ace.axonichealth.com'>https://ace.axonichealth.com</a></p>
<ul><li>“You” means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li></ul>
<h4>Conditions for Refund</h4>
<p>In order for the Goods to be eligible for a refund, please make sure that:</p>
<ul>
    <li>The service was availed in the last Seven days.</li>
    <li>The issue may be regarding Technical Issues, missed appointment without prior information, Billing Errors, Non-delivery of Services in AxonCare Clinics</li>
</ul>
<p>We reserve the right to refuse refund that does not meet the above return conditions in our sole discretion.</p>
<h4>Contact Us</h4>
<p>If you have any questions about our Refunds Policy, please contact us:</p>
<ul>
    <li>By visiting this page on our website</li>
    <li>By sending us an email: <a href='mailto:vishal.kale@axonichealth.com'>vishal.kale@axonichealth.com</a></li>
</ul>
    </div>
   </div>
  )
}

export default Refund
