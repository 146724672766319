import React, { useEffect, useState } from "react";
import "./pricing_form.scss";
import { IoMdArrowDropdown } from "react-icons/io";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AutoComplete } from "antd";
import Razorpay from "razorpay";
import { TiTick } from "react-icons/ti";
import debounce from "lodash.debounce";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { option } from "framer-motion/client";
import { IoMdCloseCircleOutline } from "react-icons/io";

const Pricing_Form = ({ packDetail, duration, masterData }) => {
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [selectStateId, setSelectStateId] = useState("");
  const [selectDistrictId, setSelectDistrictId] = useState("");
  const [popup, setPopUp] = useState(true);
  const [selectCitytId, setSelectCityId] = useState("");
  let countryName = localStorage.getItem("country");
  // formdata inputs

  const [regData, setRegData] = useState({
    organizationName: "",
    unitName: "",
    countryId: "",
    cityId: "",
    districtId: "",
    stateId: "",
    areaId: "",
    postalCode: "",
    appUrl: "",
    address: "",
    subDomain: "",
    contactNumber: "",
    organizationEmailId: "",
    appId: packDetail,
    emailId: "",
    contactPerson: "",
    paymentDetails: {
      amount:1,
        // (masterData &&
        //   masterData[packDetail - 1] &&
        //   masterData[packDetail - 1].price[duration]) ||
        // 0,
      TransactionId: "",
      planType:
        duration === 0
          ? "1 month"
          : "" || duration === 1
          ? "6 months"
          : "" || duration === 2
          ? "12 months"
          : "",
    },
  });

  console.log(regData);

  const [options, setOptions] = useState([]);

  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [area, setArea] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // subdomains

  const [data, setData] = useState({
    company: "",
    domain:
      countryName === "India"
        ? "his.axonichealth.co.in"
        : "his.axonichealth.uk",
  });

  const suffixes = ["new", 1, 2, 3, 4, 5, 6, 7, 8, 9];

  async function checkAvailability(subdomain) {
    const url = `https://dns.google/resolve?name=${subdomain}`;
    try {
      const response = await fetch(url);
      const result = await response.json();
      return !result.Answer; // If no 'Answer' key, the domain is available
    } catch {
      return false; // Treat as unavailable on error
    }
  }

  async function generateSuggestions() {
    setIsLoading(true);

    // Ensure data values are valid strings or default to an empty string
    const company = data.company?.trim() || "";
    const domain = data.domain?.trim() || "";
    const subDomain = regData.subDomain?.trim() || "";

    const baseSuggestions = [
      `${company}.${domain}`,
      `${subDomain}.${domain}`,
      `${company.slice(0, 4)}.${domain}`,
      `${subDomain.slice(0, 4)}.${domain}`,
      `${company.slice(0, 3)}.${domain}`,
    ];

    const finalSuggestions = new Set(); // Use Set to avoid duplicates

    for (let base of baseSuggestions) {
      let current = base;
      let suffixIndex = 0;

      // Check domain availability and append suffixes if needed
      while (suffixIndex < suffixes.length) {
        const isAvailable = await checkAvailability(current);

        if (isAvailable) {
          finalSuggestions.add(current);
          break; // Move to the next base suggestion
        }

        current = `${base.split(".")[0]}${suffixes[suffixIndex]}.${domain}`;
        suffixIndex++;
      }
    }

    // Generate random numeric suggestions
    const randomNumericSuggestions = Array.from({ length: 5 }, () => {
      const randomNumber = Math.floor(100 + Math.random() * 900);
      return `${company.replace(/\s+/g, "")}${randomNumber}.${domain}`;
    });

    // Combine all suggestions into a single array
    randomNumericSuggestions.forEach((suggestion) =>
      finalSuggestions.add(suggestion)
    );

    setIsLoading(false);

    // Set options dynamically based on domain length
    const domainLength = domain.length;

    setOptions(
      Array.from(finalSuggestions)
        .filter((item) => item) // Ensure no empty values
        .map((item) => ({
          label: item.toLowerCase(),
          value: item.slice(0, -(domainLength + 1)), // Dynamically trim domain
        }))
    );
  }

  const [manualInput, setManualInput] = useState("");

  function handleBlur() {
    const isInOptions = options.some((option) => option.value === manualInput);

    if (!isInOptions && manualInput.trim() !== "") {
      const domainSuffix =
        countryName === "India"
          ? ".his.axonichealth.co.in"
          : ".his.axonichealth.uk";

      notification.error({
        message: "Domain Unavailable",
        description: `The domain "${manualInput}${domainSuffix}" is not available.`,
      });
      setPopUp(true);
      setRegData({
        subDomain: "",
      });
    }
  }
  // Set default values based on the country/state/city selected
  useEffect(() => {
    setRegData((prevData) => ({
      ...prevData,
      unitName: prevData.organizationName,
      appUrl: prevData.subDomain,
      countryId: selectedCountryId,
      cityId: selectCitytId,
      districtId: selectDistrictId,
      stateId: selectStateId,
      organizationName: data.company,
    }));
  }, [
    regData.organizationName,
    regData.subDomain,
    selectedCountryId,
    selectCitytId,
    selectDistrictId,
    selectStateId,
    data.company,
  ]);

  useEffect(() => {
    if (masterData.length === 0) {
      if (countryName === "India") {
        navigate("/pricing/in");
      } else {
        navigate("/pricing/uk");
      }
    }
  }, [masterData, navigate]);

  const debouncedFetchSubDomains = debounce(generateSuggestions, 2000);

  useEffect(() => {
    if (data.company || regData.subDomain) {
      debouncedFetchSubDomains();
    }
  }, [data.company, regData.subDomain]);

  const fetchCountryList = async () => {
    try {
      let url;

      if (countryName === "India") {
        url =
          "https://subscription.axonichealth.co.in/adt/getActiveCountryList/0";
      } else if (countryName === "UK") {
        url = "https://uk.his.axonichealth.uk/adt/getActiveCountryList/0";
      }
      const response = await axios.get(url);
      setCountry(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStateList = async () => {
    try {
      let url;

      if (countryName === "India") {
        url =
          "https://subscription.axonichealth.co.in/adt/getActiveStateListByCountryId";
      } else if (countryName === "UK") {
        url =
          "https://uk.his.axonichealth.uk/adt/getActiveStateListByCountryId";
      }

      const response = await axios.post(url, {
        countryId: selectedCountryId,
      });
      setStates(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDistrictList = async () => {
    try {
      let url;

      if (countryName === "India") {
        url =
          "https://subscription.axonichealth.co.in/adt/getActiveDistrictListByStateId";
      } else if (countryName === "UK") {
        url =
          "https://uk.his.axonichealth.uk/adt/getActiveDistrictListByStateId";
      }
      const response = await axios.post(url, {
        stateId: selectStateId,
      });
      setDistrict(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCityList = async () => {
    try {
      let url;

      if (countryName === "India") {
        url =
          "https://subscription.axonichealth.co.in/adt/getActiveCityListByDistrictId";
      } else if (countryName === "UK") {
        url =
          "https://uk.his.axonichealth.uk/adt/getActiveCityListByDistrictId";
      }
      const response = await axios.post(url, {
        districtId: selectDistrictId,
      });
      setCity(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAreaList = async () => {
    try {
      let url;

      if (countryName === "India") {
        url = "https://subscription.axonichealth.co.in/adt/getAreaByCityId";
      } else if (countryName === "UK") {
        url = "https://uk.his.axonichealth.uk/adt/getAreaByCityId";
      }
      const response = await axios.post(url, {
        cityId: selectCitytId,
      });
      setArea(response.data.listObject);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCountryList();
  }, []);

  useEffect(() => {
    if (selectedCountryId) fetchStateList();
  }, [selectedCountryId]);

  useEffect(() => {
    if (selectStateId) fetchDistrictList();
  }, [selectStateId]);

  useEffect(() => {
    if (selectDistrictId) fetchCityList();
  }, [selectDistrictId]);

  useEffect(() => {
    if (selectCitytId) fetchAreaList();
  }, [selectCitytId]);

  const handlePaymentSuccess = (response) => {
    const transactionId = response.razorpay_payment_id;

    const updatedRegData = {
      ...regData,
      subDomain:
        countryName === "India"
          ? `${regData.subDomain}.his.axonichealth.co.in`
          : `${regData.subDomain}.his.axonichealth.uk`,
      contactNumber:
        countryName === "India"
          ? `+91${regData.contactNumber}`
          : `+44${regData.contactNumber}`,
      paymentDetails: {
        ...regData.paymentDetails,
        TransactionId: transactionId,
        amount: regData.paymentDetails.amount,
      },
    };

    setRegData(updatedRegData);

    setTimeout(() => {
      regDataTransfer(updatedRegData);
    }, 500);
  };

  const regDataTransfer = async (updatedRegData) => {
    try {
      const stringifiedPaymentDetails = JSON.stringify(
        updatedRegData.paymentDetails
      );

      const payload = {
        ...updatedRegData,
        paymentDetails: stringifiedPaymentDetails,
      };

      console.log(payload);

      let url;

      if (countryName === "India") {
        url =
          "https://subscription.axonichealth.co.in/api/createOrganizationFromWeb";
      } else if (countryName === "UK") {
        url = "https://uk.his.axonichealth.uk/api/createOrganizationFromWeb";
      }

      const response = await axios.post(url, payload);
      if (response.data.status === "success") {
        setRegData({
          organizationName: "",
          unitName: "",
          countryId: "",
          cityId: "",
          districtId: "",
          stateId: "",
          areaId: "",
          postalCode: "",
          appUrl: "",
          address: "",
          subDomain: "",
          contactNumber: "",
          organizationEmailId: "",

          emailId: "",
          contactPerson: "",
        });

        navigate("/complete_Payment");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initiatePayment = () => {
    if (typeof window.Razorpay === "undefined") {
      console.error("Razorpay SDK not loaded");
      return;
    }

    
    const options = {
      key: "rzp_live_f66AdSlCPkTR3h",
      amount:
        countryName === "India"
          ? regData.paymentDetails.amount * 1.18 * 100
          : regData.paymentDetails.amount * 1.2 * 100,

      currency: countryName === "India" ? "INR" : "GBP",
      // amount: regData.paymentDetails.amount * 100,
      // currency: "INR",
      name: regData.organizationName,
      description: "Test Transaction",
      handler: handlePaymentSuccess,
      prefill: {
        name: regData.contactPerson,
        email: regData.organizationEmailId,
        contact: regData.contactNumber,
      },
      theme: { color: "#3399cc" },
    };

    // Open Razorpay payment modal
    const rzp = new window.Razorpay(options);
    rzp.open();

    rzp.on("payment.failed", function (response) {
      console.log("Payment Failed:", response.error);
      alert("Payment Failed! " + response.error.description);
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    initiatePayment();
  };

  return (
    <>
      <div class="popup-form-parent parent">
        <div class="content-cont container">
          <h3>
            Please fill out the form below to proceed to the payment page.
          </h3>

          {popup && (
            <div class="popup">
              <strong>
                <span>Note</span>
              </strong>

              <div className="close" onClick={() => setPopUp(!popup)}>
                <IoMdCloseCircleOutline />
              </div>
              <span>
                Please enter your organisation name first to view the available
                Application URL suggestions. Please choose the URL you like from
                the suggestions drop down .{" "}
              </span>
            </div>
          )}
        </div>
        {masterData.length > 0 && (
          <div class="popup-form-cont container">
            <div className="pricing-box">
              <div className="content">
                <h1>
                  {countryName === "India" ? "₹" : "£"}
                  {new Intl.NumberFormat("en-IN").format(
                    masterData[packDetail - 1].price[duration]
                  )}
                </h1>
                <h2>
                  <span> {masterData[packDetail - 1].name}</span>
                </h2>
                <p>
                  <strong>
                    {duration === 0
                      ? "Monthly"
                      : duration === 1
                      ? "6 Months"
                      : duration === 2
                      ? "Yearly"
                      : null}
                  </strong>
                </p>
              </div>
              <div className="included">
                <h3>Included In the Plan</h3>
                <p>
                  No. of hours of virtual training:{" "}
                  <span>{masterData[packDetail - 1].traing_duration}</span>
                </p>

                <p>
                  Email support: <span>Free</span>
                </p>
              </div>
              <div className="included">
                <h3>Optional Add-ons</h3>

                <p>
                  Configuration :{" "}
                  <span>{masterData[packDetail - 1].configuration_cost}</span>
                </p>
                <p>
                  Training :{" "}
                  <span>{masterData[packDetail - 1].training_cost}</span>
                </p>
              </div>

              <table>
                <tbody>
                  <tr>
                    <td>Plan Cost</td>
                    <td>
                      {countryName === "India" ? "₹" : "£"}
                      {new Intl.NumberFormat(
                        countryName === "India" ? "en-IN" : "en-GB"
                      ).format(masterData[packDetail - 1].price[duration])}
                    </td>
                  </tr>
                  <tr>
                    <td>Tax Applied</td>
                    {countryName === "India" ? (
                      <td>18% (GST)</td>
                    ) : (
                      <td>20% (VAT)</td>
                    )}
                  </tr>
                  <tr>
                    <td>Total Cost</td>
                    <td>
                      {countryName === "India" ? "₹" : "£"}
                      {Intl.NumberFormat(
                        countryName === "India" ? "en-IN" : "en-GB"
                      ).format(
                        countryName === "India"
                          ? masterData[packDetail - 1].price[duration] * 1.18
                          : masterData[packDetail - 1].price[duration] * 1.2
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* <div className="action">
                {countryName === "India" && (
                  <Link className="btn" to="/pricing/in">
                    Change Plan
                  </Link>
                )}
                {countryName === "UK" && (
                  <Link className="btn" to="/pricing/uk">
                    Change Plan
                  </Link>
                )}
              </div> */}
            </div>

            <form action="" onSubmit={handleFormSubmit}>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  value={data.company}
                  required
                  onChange={(e) =>
                    setData({ ...data, company: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    data.company !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Organization Name
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <AutoComplete
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    padding: "0px",
                    fontSize: "16px",
                    border: "none",
                    outline: "none",
                  }}
                  options={
                    isLoading ? [{ label: "Loading...", value: "" }] : options
                  }
                  className="form-input autocomplete"
                  type="email"
                  required
                  value={regData.subDomain.toLowerCase()}
                  onChange={(value) => {
                    // Allow only alphabets and numbers
                    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
                    setRegData({ ...regData, subDomain: sanitizedValue });
                    setManualInput(sanitizedValue);
                  }}
                  onBlur={handleBlur}
                />

                <div class="url-label">
                  {countryName === "India"
                    ? "his.axonichealth.co.in"
                    : "his.axonichealth.uk"}
                </div>
                <label
                  for=""
                  className={
                    regData.subDomain !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Application URL
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="email"
                  className="form-input"
                  value={regData.organizationEmailId}
                  required
                  onChange={(e) =>
                    setRegData({
                      ...regData,
                      organizationEmailId: e.target.value,
                    })
                  }
                />
                <label
                  for=""
                  className={
                    regData.organizationEmailId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Organization Email-id
                </label>
              </div>

              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  value={regData.contactPerson}
                  required
                  onChange={(e) =>
                    setRegData({ ...regData, contactPerson: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.contactPerson !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Contact Person Name
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="tel"
                  className="form-input tel_input"
                  value={regData.contactNumber}
                  required
                  maxLength={countryName === "India" ? 10 : 15}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (countryName === "India") {
                      if (/^\d*$/.test(value)) {
                        setRegData({ ...regData, contactNumber: value });
                      }
                    } else {
                      if (/^[\d\s]*$/.test(value)) {
                        setRegData({ ...regData, contactNumber: value });
                      }
                    }
                  }}
                />
                <div class="country_code">
                  {countryName === "India" ? "+91" : "+44"}
                </div>
                <label
                  for=""
                  className={
                    regData.contactNumber !== ""
                      ? "active-label input_label tel_label"
                      : "input_label tel_label"
                  }
                >
                  Contact Number
                </label>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="email"
                  className="form-input"
                  required
                  value={regData.emailId}
                  onChange={(e) =>
                    setRegData({ ...regData, emailId: e.target.value })
                  }
                />
                <label
                  for=""
                  className={
                    regData.emailId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Personal Email-id
                </label>
              </div>

              <div class={error ? "form-row error" : "form-row"}>
                <input
                  type="text"
                  className="form-input"
                  required
                  value={regData.address}
                  onChange={(e) =>
                    setRegData({
                      ...regData,
                      address: e.target.value,
                    })
                  }
                />
                <label
                  for=""
                  className={
                    regData.address !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Address
                </label>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  className="form-input"
                  required
                  onChange={(e) => setSelectedCountryId(e.target.value)} // Update selectedCountryId
                  value={selectedCountryId}
                >
                  <option value="">Select Country</option>

                  {country.map((item, index) => (
                    <option key={index} value={item.countryId}>
                      {item.countryName}
                    </option>
                  ))}
                </select>

                <label
                  for=""
                  className={
                    regData.selectedCountryId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Country
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  required
                  id=""
                  className="form-input"
                  value={selectStateId}
                  onChange={(e) => setSelectStateId(e.target.value)}
                >
                  <option value="">Select State</option>
                  {states &&
                    states.map((item, key) => (
                      <option value={item.stateId} key={key}>
                        {item.stateName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.selectStateId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  {countryName === "India" ? "State" : "State/Region"}
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  className="form-input"
                  required
                  value={selectDistrictId}
                  onChange={(e) => setSelectDistrictId(e.target.value)}
                >
                  <option value="">Select District</option>
                  {district &&
                    district.map((item, key) => (
                      <option value={item.districtId} key={key}>
                        {item.districtName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.selectDistrictId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  {countryName === "India" ? "District" : "District/County"}
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  required
                  className="form-input"
                  value={selectCitytId}
                  onChange={(e) => setSelectCityId(e.target.value)}
                >
                  <option value="">Select City</option>
                  {city &&
                    city.map((item, key) => (
                      <option value={item.cityId} key={key}>
                        {item.cityName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.selectCitytId !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  City
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div
                class={
                  error
                    ? "form-row select-input error"
                    : "form-row select-input"
                }
              >
                <select
                  type="text"
                  id=""
                  className="form-input"
                  value={regData.area}
                  required
                  onChange={(e) =>
                    setRegData({ ...regData, area: e.target.value })
                  }
                >
                  <option value="">Select Area</option>
                  {area &&
                    area.map((item, key) => (
                      <option value={item.areaId} key={key}>
                        {item.areaName}
                      </option>
                    ))}
                </select>
                <label
                  for=""
                  className={
                    regData.area !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Area
                </label>
                <span className="arrow">
                  <IoMdArrowDropdown />
                </span>
              </div>
              <div class={error ? "form-row error" : "form-row"}>
                {countryName === "India" && (
                  <input
                    type="tel"
                    className="form-input"
                    maxLength={6}
                    required
                    value={regData.postalCode}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        setRegData({ ...regData, postalCode: value });
                      }
                    }}
                  />
                )}
                {countryName === "UK" && (
                  <input
                    type="text"
                    className="form-input"
                    maxLength={10}
                    required
                    value={regData.postalCode?.toUpperCase() || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[\s\dA-Za-z]*$/.test(value)) {
                        setRegData({ ...regData, postalCode: value });
                      }
                    }}
                  />
                )}

                <label
                  for=""
                  className={
                    regData.postalCode !== ""
                      ? "active-label input_label"
                      : "input_label"
                  }
                >
                  Postal Code
                </label>
              </div>
              <div className="btn-tab">
                <button className="btn" type="submit">
                  Proceed to Payment
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default Pricing_Form;
