import React, { useState } from "react";
import "./Price.scss";
import { FaCheckDouble } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Price({ setPackDetail, setDuration, setMasterData }) {
  const navigate = useNavigate();

  const plansData = [
    {
      id: 1,
      name: "Lite",
      price: [17000, 38500, 115000],
      old: [18700, 40000, 117500],
      traing_duration: "4hrs",
      configuration_cost: "₹ 1200/day",
      training_cost: "₹ 800/hour",
      users :"Upto 5 Users",
      beds :"5 to 10 Beds",
      extra: "",
      features: [
       
        "Patient Registration",
        "Appointment Scheduling",
        "Patient Admission/Discharge/Transfer",
        "EMR (Outpatient & Inpatient)",
        "OPD Billing",
        "IPD Billing",
        "Fund Management",
        "WhatsApp Integration",
        "NABH Quality Indicators",

      ],
      featuresAll: [
       
        "Patient Registration",
        "Appointment Scheduling",
        "Patient Admission/Discharge/Transfer",
        "EMR (Outpatient & Inpatient)",
        "OPD Billing",
        "IPD Billing",
        "Fund Management",
        "WhatsApp Integration",
        "NABH Quality Indicators",
      ],
    },
    {
      id: 2,
      name: "Pro",
      price: [100000, 225000, 650000],
      old: [102000, 250000, 670000],
      traing_duration: "20hrs",
      configuration_cost: "₹ 1200/day",
      training_cost: "₹ 800/hour",
      users :"Upto 10 Users",
      beds :"10 to 50 Beds",
      extra: "All Modules of Lite Plan plus",
      features: [
       
        "Nursing Module",
        "Inventory Module",
        "Pharmacy Module",
        "Lab Information System",
        "Insurance & Corporate Billing",
        "Package Creation",

      ],
      featuresAll: [
        
        "Patient Registration",
        "Appointment Scheduling",
        "Patient Admission/Discharge/Transfer",
        "EMR (Outpatient & Inpatient)",
        "OPD Billing",
        "IPD Billing",
        "Fund Management",
        "WhatsApp Integration",
        "NABH Quality Indicators",
        "Nursing Module",
        "Inventory Module",
        "Pharmacy Module",
        "Lab Information System",
        "Insurance & Corporate Billing",
        "Package Creation",

      ],
    },
    {
      id: 3,
      name: "Advance",
      price: [200000, 450000, 1300000],
      old: [202000, 452000, 1350000],
      traing_duration: "40hrs",
      configuration_cost: "₹ 1200/day",
      training_cost: "₹ 800/hour",
      users :"Upto 25 Users",
      beds :"50+ Beds",
      extra: "All Modules of Pro Plan +",
      features: [
      
        
        "Procurement Module",
        "Blood Bank",
        "CSSD",
        "Linen & Laundry",
        "Diet & Kitchen",
        "Mortuary",
        "Comprehensive EMR",
        "User-Defined Examination Templates",
        "Operation Theatre Management",
        "AI-Enabled Features",
   
      ],
      featuresAll: [
        
        "Patient Registration",
        "Appointment Scheduling",
        "Patient Admission/Discharge/Transfer",
        "EMR (Outpatient & Inpatient)",
        "OPD Billing",
        "IPD Billing",
        "Fund Management",
        "WhatsApp Integration",
        "NABH Quality Indicators",
        "Nursing Module",
        "Inventory Module",
        "Pharmacy Module",
        "Lab Information System",
        "Insurance & Corporate Billing",
        "Package Creation",
        "Procurement Module",
        "Blood Bank",
        "CSSD",
        "Linen & Laundry",
        "Diet & Kitchen",
        "Mortuary",
        "Comprehensive EMR",
        "User-Defined Examination Templates",
        "Operation Theatre Management",
        "AI-Enabled Features",

      ],
    },
  ];

  const [plan, setPlan] = useState(0); // Selected duration: Monthly, Semi Annual, Annual
  const [listView, setListView] = useState(false);
  const [pop, setPop] = useState(true); // Pop modal state
  const [selectedPlan, setSelectedPlan] = useState(null); // Selected plan for the modal

  // Handle "Know More" button click
  const handlePlanInfo = (planData) => {
    setSelectedPlan(planData); // Set the selected plan for the modal
    setPop(true); // Open the modal
  };

  // Handle plan selection
  const handlePlanDetails = (id) => {
    setPackDetail(id);
    setDuration(plan);
    setMasterData(plansData);
    navigate("/pricing_form");
  };

  return (
    <>
      {pop && selectedPlan && (
        <div className="pop parent">
          <div className="bg" onClick={() => setPop(false)}></div>
          <div className="pop-container container">
            <div className="left">
              <h3>Features included in {selectedPlan.name}</h3>
              <div className="lists">
                {selectedPlan.featuresAll.map((feature, index) => (
                  <div className="list_items active" key={index}>
                    <span className="icon">
                      <FaCheckDouble />
                    </span>
                    {feature}
                  </div>
                ))}
              </div>
            </div>
            <div className="right">
              <div className="content">
                <h1>{selectedPlan.name}</h1>
                <p className="duration">
                  {["Monthly", "Semi Annual", "Annual"][plan]} Plan
                </p>
                <div className="included">

                  <h3>Included In the Plan</h3>
                  <p>
                    No. of hours of virtual training:{" "}
                    <span>{selectedPlan.traing_duration}</span>
                  </p>
              
                  <p>
                    Email support: <span>Free</span>
                  </p>
                </div>
                <div className="included">
                <h3>Optional Add-ons</h3>
             
                  <p>
                    Configuration :{" "}
                    <span>{selectedPlan.configuration_cost}</span>
                  </p>
                  <p>
                    Training : <span>{selectedPlan.training_cost}</span>
                  </p>
                </div>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td>Plan Cost</td>
                    <td>₹{Intl.NumberFormat("en-IN").format(selectedPlan.price[plan]* 1)}</td>
                  </tr>
                  <tr>
                    <td>Tax Applied</td>
                    <td>18% (GST)</td>
                  </tr>
                  <tr>
                    <td>Total Cost</td>
                    <td>₹{Intl.NumberFormat("en-IN").format(selectedPlan.price[plan]* 1.18)}</td>
                  </tr>
                </tbody>
              </table>
              <div className="btn-box">
                <button
                  className="btn"
                  onClick={() => handlePlanDetails(selectedPlan.id)}
                >
                  Select Plan
                </button>
                <button className="btn-2" onClick={() => setPop(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="price parent">
        <div className="container price-container">
          <h2>Our Pricing Plans</h2>
          <p>
            ACE offers tailored solutions designed to grow with you. Whether
            you’re a small clinic or a large hospital, ACE has the tools you
            need.
          </p>
          <div className="price-box">
            <div className="plans glass">
              {["Monthly", "Semi Annual", "Annual"].map((label, index) => (
                <div
                  key={index}
                  className={plan === index ? "plan active" : "plan"}
                  onClick={() => setPlan(index)}
                >
                  {label}
                </div>
              ))}
            </div>
            <div className="cards">
              {plansData.map((planData) => (
                <div className="card" key={planData.id}>
                  <div className="card-top">
                    <div className="plan-type">{planData.name}</div>
                    <span className="strike">
                      
                      ₹ {Intl.NumberFormat("en-IN").format(planData.old[plan]* 1)}
                    </span>
                    <h1>
                      ₹ {Intl.NumberFormat("en-IN").format(planData.price[plan]* 1)}
                      <span>*</span>
                    </h1>
                    <div className="lists">
                    <div className="high-box">
                        <div className="highlight">
                          {planData.beds}

                        </div>
                        <div className="highlight">
                          {planData.users}

                        </div>
                      </div>

                      {planData.extra.length > 0 && <div className="before active" >
                      
                        {planData.extra}
                      </div>}
                      {planData.features
                        .slice(0, listView ? undefined : 7)
                        .map((feature, index) => (
                          <div className="list_items active" key={index}>
                            <span className="icon">
                              <FaCheckDouble />
                            </span>
                            {feature}
                          </div>
                        ))}
                    </div>
                  </div>
                  <button
                    className="btn"
                    onClick={() => handlePlanInfo(planData)}
                  >
                    Know More
                  </button>
                </div>
              ))}
            </div>
            <button className="btn-2" onClick={() => setListView(!listView)}>
              {listView ? "Show Less" : "Full Comparison"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Price;
