import React from 'react'
import { TiTick } from "react-icons/ti";
import "./thank_you.scss"
import { Link } from 'react-router-dom';
const Thank_You_Page = () => {
  return (
    <>
         <div class="thank_you parent">
         <div class="thank_you_cont cont">
         
         <div class="box-content">
         <div class="right_icon">
            <TiTick />
          </div>
         <h1>Thanks for Choosing ACE</h1>
         <Link class="btn" to="/" >
Back To Home
         </Link>
         </div>


         </div>

        
        </div>
    </>
  )
}

export default Thank_You_Page
