import "./Header.scss";
import Hamburger from "hamburger-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/acelogo.webp";
import React, { useEffect, useState, useRef } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";

function Header() {
  const [activeOption, setActiveOption] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleScroll = () => {
    if (window.scrollY > 20) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const [isOpen, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("click", closeDropdownOnClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", closeDropdownOnClickOutside);
    };
  }, []);

  const closeDropdownOnClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false); // Close dropdown if click is outside
    }
  };

  const [selectedCountry, setSelectedCountry] = useState(() => {
    const savedCountry = localStorage.getItem("country");
    if (savedCountry) {
      return savedCountry;
    } else {
      localStorage.setItem("country", "India");
      return "India";
    }
  });

  useEffect(() => {
    localStorage.setItem("country", selectedCountry);
  }, [selectedCountry]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const headerTop = () => {
    setIsHeaderVisible(false); // Hide the header
  };

  const countryList = [
    { countryName: "India", id: 1, path: "/pricing/in" },
    { countryName: "UK", id: 2, path: "/pricing/uk" },
  ];

  const handleSelectCountry = (country) => {
    setSelectedCountry(country);
    localStorage.setItem("country", country);
    setIsDropdownOpen(false); // Close the dropdown on selection

    // Redirect only if on a pricing page
    if (location.pathname.includes("/")) {
      if (country === "India" && location.pathname !== "/pricing/in") {
        navigate("/pricing/in");
      } else if (country === "UK" && location.pathname !== "/pricing/uk") {
        navigate("/pricing/uk");
      }
    }

    window.location.reload();


  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle the dropdown state
  };

  return (
    <>
      {isHeaderVisible && (
        <div className="header-top parent">
          <div className="header-top-container container">
            <div className="header-top-left">
              <p className="left-p">Choose Country</p>
            </div>
            <div className="header-top-right" ref={dropdownRef}>
              <div className="dropdown-container" >
                <div className="dropdown-header" onClick={toggleDropdown}>
                  {selectedCountry || "Select a country"}
                  <span className={
                    isDropdownOpen ? "dropdown-arrow active" : "dropdown-arrow"
                  }>
                <IoIosArrowDown />
                  </span>
                </div>
                {isDropdownOpen && (
                  <div className="dropdown-options">
                    {countryList.map((item) => (
                      <div
                        key={item.id}
                        className={
                          location.pathname === item.path
                            ? " dropdown-option active"
                            : " dropdown-option"
                        }
                        onClick={() => handleSelectCountry(item.countryName)}
                      >
                        {item.countryName}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={`header parent ${isScrolled ? "scrolled" : ""} ${
          !isHeaderVisible ? "normalHeader" : ""
        }`}
      >
        <div className="container">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Logo" />
            </a>
          </div>

          <div className="links glass">
            <Link to="/"> Home </Link>
            <a href="/#about_link"> About </a>

            <>
              {selectedCountry === "India" && (
                <Link to={"/pricing/in"}>Pricing</Link>
              )}
              {selectedCountry === "UK" && (
                <Link to={"/pricing/uk"}>Pricing</Link>
              )}
            </>
            <>
              {selectedCountry === "India" && (
                <Link to={"/pricing/in"} className="btn">
                  {" "}
                  Subscribe{" "}
                </Link>
              )}
              {selectedCountry === "UK" && (
                <Link to={"/pricing/uk"} className="btn">
                  Subscribe
                </Link>
              )}
            </>

            {/* dropdown------------------------ */}
          </div>

          <div className="navbtn glass" onClick={() => setOpen(true)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="mobilenav">
          <div className="close glass" onClick={() => setOpen(false)}>
            <span></span>
            <span></span>
          </div>

          <div className="mobile-links">
            <Link to="/" onClick={() => setOpen(false)}>
              Home
            </Link>
            <a href="#about-link" onClick={() => setOpen(false)}>
              About
            </a>
            <>
              {selectedCountry === "India" && (
                <Link to={"/pricing/in"} onClick={() => setOpen(false)}>
                  Pricing
                </Link>
              )}
              {selectedCountry === "UK" && (
                <Link to={"/pricing/uk"} onClick={() => setOpen(false)}>
                  Pricing
                </Link>
              )}
            </>
            <Link to="/pricing" className="btn" onClick={() => setOpen(false)}>
              Subscribe
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
