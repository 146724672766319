import React from 'react'
import "./term.scss"
const Term_conditions = () => {
  return (
    <>
    <div className="term-parent parent">
        <div className="term-cont container">
            <p>EFFECTIVE DATE: 19th Dec 2024</p>
            <p> This Terms of Use and the policies referred to herein contain the terms and conditions that apply to your use of our Platform and our Mobile Application by the name AxonCare, developed by Axonic Health Private Limited, a corporation whose principal place of business is at 403, 4th Floor, Kapil Zenith IT Park, Bavdhan, Pune 411021, INDIA (“We/Our/Us/Company”) from time to time and services provided therein (“Platform “). By using and continuing to use the Platform, the User (“You/Your”) representing the Organization with which the Company has entered into an agreement for use of the Platform (“Client”) agrees to abide by this Agreement. These Terms of Use along with the Privacy policy together shall hereinafter be referred to as the Terms.</p>
            <p> PLEASE READ THESE TERMS CAREFULLY. THIS AGREEMENT GOVERNS YOUR USE OF THE PLATFORM AND/OR OUR APP AND SERVICES MADE AVAILABLE THROUGH THE PLATFORM AND/OR OUR APP. IF YOU DO NOT AGREE TO THE TERMS, PLEASE DO NOT USE THE PLATFORM.</p>
            <p>Your access or use of the Platform creates a binding and enforceable legal agreement between you, the Platform user, and Us and signifies your understanding, acceptance and consent to be bound by the terms and conditions contained in this Agreement. We grant you permission to use the Platform subject to the restrictions in these Agreement.</p>
            <p>BY USING THE PLATFORM, AND/OR BY REGISTERING WITH US, YOU AGREE THAT YOU ACCEPT THESE TERMS.</p>
            <p>NOTE: The expression ‘you’ and its grammatical variations refer to the individual who is accessing this Platform. If you are not the user for whom the Services are being sought, then by accessing this Platform, you assume full responsibility to communicate the terms and conditions under which this Platform and the Services on the Platform are being made available to the user. Prior to accessing the Platform, you must ensure that you have been lawfully authorized by the client to share the user’s Personal Information, records and information with the Company for the purposes envisaged in this Agreement and the Privacy Policy and obtain the services contained herein for the user.</p>
            <h4>ABOUT THE PLATFORM</h4>
            <p>Everything we offer on the Platform is referred to in this Agreement collectively as the “Services” Some information available on the Platform is viewable without registering with us, but to avail benefit of most Services, you must register as a member and authorize the use and disclosure of the Client’s patient’s personal and health information for purposes of allowing us to provide the Services and as otherwise disclosed in our <a href="https://ace.axonichealth.com"> Privacy Policy</a>. </p>
            <h4> LICENSE TO USE THE SITE</h4>
            <p>The Company grants you a limited license to access and make use of the Platform, subject to the Terms. Without the express written consent of the Company, you may not reproduce, duplicate, copy, download, sell or otherwise exploit for any commercial purpose the Platform and any portion hereof. This limited license terminates automatically, without notice to you, if you breach these Terms.</p>
            <h4>Purchases</h4>
            <p>If you wish to purchase any our product or service (“Purchase”), you may send the request in one of the following ways:</p>
            <ul>
                <li>send the request via contact form,</li>
                <li>send an email to info@axonichealth.com</li>
            </ul>
            <h4>PAYMENT OF FEES</h4>
            <p>You acknowledge that you or any authorized individual acting on behalf of the Client is responsible for all fees charged by the Company for the Services. You understand that you (or anyone else authorized by you) must pay these fees as per the agreement between the Company and the Client. You also acknowledge that the continuous provision of the Services is contingent upon timely payment of fees by the Client to the Company.</p>
            <h4>TERMINATION</h4>
            <p>We may terminate, withdraw, discontinue or suspend access to our Platform immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach   the-Terms.
            All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
            <h4>CONTENT, COPYRIGHTS AND TRADEMARKS</h4>
            <p>All text, material, data, and information, data files, description of our services or/and our products, graphics, images, user interfaces, visual interfaces, photographs, trademarks, logos and computer code etc. (“Content”), contained on the Platform is owned, controlled or licensed by or to Company, and is protected by applicable intellectual property and other laws, including trademark and copyright laws. Company owns and retains all copyrights in the Content.
            You acknowledge that We do not make any representations or warranties about the accuracy or currency of the Content which you may have access to. Under no circumstances is Company liable in any way for any Content, including, but not limited to: any infringing Content, any errors or omissions in Content, or for any loss or damage of any kind incurred as a result of the use of any Content posted, transmitted, linked from, or otherwise accessible through or made available via the Platform.</p>
            <p>You agree that you are solely responsible for your reuse of Content made available through the Platform, including providing proper attribution. You should review the terms of the applicable license before you use the Content so that you know what you can and cannot do.
Except as expressly provided in these Terms, no part of the Platform and no Content may be copied, reproduced, modified, published, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including “mirroring”) to any other computer, server, web site or other medium for publication or distribution or for any commercial enterprise, without Company’s express prior written consent. You also agree not to modify, rent, lease, loan, sell, distribute, transmit, broadcast, or create derivative works based on the Content or the Platform, in whole or in part, by any means.
Company’s logos and trademarks are trademarks and the property of Company. The appearance, layout, colour scheme, and design of the Platform are protected trade dress. You do not receive any right or license to use the foregoing. We may use and incorporate into the Platform any suggestions or other feedback you provide, without payment or condition.
You may use the Platform or/and Content solely for your commercial purposes and/or to learn about Company’s products and services, and solely in compliance with these Terms; provided that you not remove any proprietary notice language in Content or part of Content, do not copy or post such Content or part of Content on any networked computer or broadcast it in any media, make no modifications to any such Content or part of Content and not make any additional representations or warranties relating to Platform, such Content or part of Content or/and Company’s products or/and services. Any unauthorized use terminates the permission or license granted by Company to you to use the Platform.</p>
<h4>FEEDBACK AND SUGGESTIONS</h4>
<p>We appreciate and welcome your comments and suggestions on the Company’s Platform. You agree that the Company may use your feedback, suggestions, or ideas in any way, including in future modifications of the Platform or other Services, products, advertising or marketing materials. You grant the Company a perpetual, worldwide, fully transferable, sublicensable, irrevocable, royalty free license to use the feedback, suggestions and ideas you provide to the Company in any way. The Company will not sell, publish or share your feedback in a way that could identify you without explicit permission.</p>
<h4>Prohibited use of the Platform</h4>
<p>By accessing the Platform, you agree that you will not:</p>
<ul>
  <li>Use the Platform in violation of these Terms;</li>
  <li>Copy, modify, create a derivative work from, reverse engineer or reverse assemble the Platform, any user accounts, the technology and equipment supporting the Platform, or otherwise attempt to discover any source code, or allow any third party to do so;</li>
  <li>Use the Service outside of the scope of the limited license herein granted, sell, assign, sublicense, distribute, copy, modify, adapt, translate, prepare derivative works from, commercially exploit, grant a security interest in or otherwise transfer any right in, or make available to a third party, the Content or Platform in any way, including but not limited to use for the sole purpose of obtaining a competitive advantage against the Company;</li>
  <li>Register, attempt to register, or assist anyone else to register any trademark, trade name, service marks, logos, domain names and other distinctive brand features, a copyright or other proprietary rights associated with the Company;</li>
  <li>Reverse engineer, disassemble, or decompile any software or otherwise attempt to discover any source code or trade secrets related to the Service, in any manner, except as permitted by applicable law;</li>
  <li>Use or launch any automated system, including without limitation “deep-link,” “page-scrape,” “robots,” “spiders,” or “offline readers” or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any Content;</li>
  <li>Use the Platform in any manner that damages, disables, overburdens, or impairs the Platform or interferes with any other party’s use and enjoyment of the Platform;</li>
  <li>Mirror or frame the Platform or any part of it on any other website or web page;</li>
  <li>Use data mining, robots, or other data gathering devices on or through the Platform;</li>
  <li>Post incomplete, false, or misleading information, impersonate another person, or misrepresent your affiliation with a person or entity;</li>
  <li>Send any prohibited content which is illegal and harmful and that induces conduct which is unlawful, defamatory, obscene, racial, offensive, pornographic, or culturally offensive or otherwise reasonably likely to generate such conduct;</li>
  <li>Post or disclose Personal Information about another person without their consent, infringe anyone’s intellectual property rights, including copyrights, trademarks, patents, and trade secrets. Infringe anyone’s privacy or publicity rights, which include but are not limited to impersonating another person or entity or posting images of someone without their permission;</li>
  <li>Sell, transfer, or assign any of your rights to use the Platform to a third party without our express written consent;</li>
  <li>Attempt to gain unauthorized access to the Platform;</li>
  <li>Probe, scan, or test the vulnerability of the Platform or any network connected to the Platform, nor breach the security or authentication measures on the Platform or any network connected to the Platform;</li>
  <li>Engage in activities which include but are not limited to hacking other computers or servers, including the Company’s; or violate any requirements, procedures, policies, or rules of networks related to the system;</li>
  <li>Upload files that contain viruses, worms, corrupted files, or any other similar software, programs, or malicious content that may damage the operation of systems hosting the Company’s Platform or another’s computer;</li>
  <li>Engage in any activity that restricts or inhibits any other account holder from using or enjoying the Services;</li>
  <li>Indulge in criminal or terrorist activity against any nation or society, or cause any threat or harassment to any person or public as a whole;</li>
  <li>Take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Platform or any systems or networks connected to the Platform;</li>
  <li>Use any device, software, or routine to interfere or attempt to interfere with the proper working of the Platform;</li>
  <li>Violate any applicable laws or regulations.</li>
</ul>
<h4>PRIVACY POLICY</h4>
<p>By using the Platform, you accept the terms of Privacy Policy which is an integral part of these Terms.
Please check our Privacy Policy to learn more <a href='https://ace.axonichealth.com/privacy'>https://ace.axonichealth.com/privacy-policy</a></p>
<h4>THIRD-PARTY LINKS</h4>
<p>Our Platform may contain links to third-party web sites or services that are not owned or controlled by Company. Links on the Platform to third party web sites or information are           
Such links do not constitute or imply an endorsement, sponsorship, or recommendation by Company of the third party, the third-party web site, or the information there.
Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services or for the availability of any such web sites. You further acknowledge and agree that Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.       </p>
<h4>ADDITIONAL TERMS</h4>
<p>Certain Services on the Platform may have additional terms (such as policies, guidelines, and rules) that will further govern your use of that particular Service and supplement this Agreement. If you choose to register for or access any such service, you will be presented with any relevant additional terms and conditions at that time. By using those Services, you agree to comply with such additional guidelines and rules, which shall be deemed to have been incorporated as terms of this Agreement.</p>
<h4>CHANGES</h4>
<p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time without giving you prior notice. Your use of the Platform following any such modification constitutes your acceptance to follow and be bound by these Terms as modified. The last date these Terms were revised is set forth above.</p>
<h4>CHANGES TO THE SERVICES</h4>
<p>We may from time to time add a new service to the Services, substitute a new service for one of the existing Services, or discontinue or suspend one of the existing Services. Information about the new services will be included on the Platform, and the users of new services will be governed by this Agreement. You agree that the Company will not be liable to you or any third party for Any suspension or discontinuation of any of the Services.</p>

<h4>DISCLAIMER; LIMITATIONS OF LIABILITY</h4>
<p>To the maximum extent permitted by applicable law, Company disclaims any and all representations, warranties and conditions relating to Platform and the use of Platform (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).
To the extent that the Platform and Content are provided free of charge, Company will not be liable for any loss or damage of any nature.</p>
<p>Company makes no representations about the suitability, reliability, availability, timeliness, security or accuracy of the platform or the content for any purpose. The platform and its content are delivered on an “as-is” and “as-available” basis. The content may include inaccuracies or typographical errors or other errors or inaccuracies and may not be complete or current.
In no event shall company and/or its affiliates be liable or responsible for any direct, indirect, incidental, consequential, special or exemplary damages of any kind, including without limitation, lost profits or lost opportunities, even if advised of the possibility of such damages in advance and regardless of the cause of action upon which any such claim is based, including but not limited to, any damages, liability or injuries caused by any failure of use, performance, error, omission, interruption, deletion, defect, delay in operation of the software or transmission, computer virus, communication line failure, theft or destruction of or unauthorized access to, alteration of, or use, whether for breach of contract, tort, negligence or any other cause of action.
Your sole remedy against company for dissatisfaction with the platform or any content is to stop using the platform or any such content
The limitations of liability in this section do not apply to breaches of intellectual property provisions by you or your indemnification obligations relating hereunder.</p>

<h4>INDEMNIFICATION</h4>
<p>You understand and agree that you are responsible for your use of the Platform. You agree to indemnify, defend and hold harmless Company, its directors, affiliated companies, employees, agents, and any third-party information providers from and against all claims, losses, expenses, damages and costs (including, but not limited to, direct, incidental, consequential, exemplary and indirect damages), and reasonable attorneys’ fees, resulting from or arising out of your use, misuse, or inability to use the Platform or the Content, or any violation by you of these Terms. You will not, in any event, settle any claim or matter without the written consent of the Company.</p>
<h4>APPLICABLE LAW, JURISDICTION OF COURTS AND DISPUTE RESOLUTION</h4>
<p>This Agreement shall be governed by and be interpreted in accordance with the laws of India. You hereby consent to the exclusive jurisdiction and venue of courts in the competent courts of Pune for all disputes arising out of your use of the Platform.</p>
<h4>GLOBAL AVAILABILITY</h4>
<p>The Company makes no representations that the information on the Platform is appropriate or available for use in other locations, and access to them from territories where their content is illegal or prohibited. Those who choose to access the Platform from other locations do so on their own initiative and are responsible for compliance with applicable local laws.</p>

<h4>MISCELLANEOUS</h4>
<p>The Company shall have the right to place advertisements and publicity materials of its choice, including that pertaining to parties other than itself for commercial use through electronic means on the Application.</p>
<p>Entire Agreement: These Terms constitute the entire agreement between the parties with respect to the subject matter hereof. A Company’s failure to insist upon or enforce strict performance of any term or provision of these Terms shall not be construed as a waiver of any term, provision or right.

Force Majeure: The Company shall not be liable for any downtime or delay or unavailability of the Application caused by circumstances beyond the Company’s reasonable control, including without limitation, acts of God, acts of government, floods, fires, earthquakes, civil unrest, acts of terror, pandemic, strikes or other labour problems, internet service provider failures or delays, or denial of service attacks.


Severability: If any part of these Terms is determined in arbitration or by a court of competent jurisdiction to be invalid or unenforceable, that part shall be limited or eliminated to the minimum extent necessary so that the remainder of these Terms is fully enforceable and legally binding.


Governing Law and Jurisdiction: The rights and obligations stipulated in these Terms will be governed and controlled by the laws of India and subject to the exclusive jurisdiction of the Courts in Pune.

Dispute Resolution: Any disputes arising out of your use of the Platform shall be resolved by mutual consultation between you and the Company. In case the dispute is not resolved through such mutual consultation, the parties shall refer the dispute to arbitration by a sole arbitrator to be mutually appointed by the Parties. The arbitration proceedings shall be held as per the Arbitration and Conciliation Act, 1996. The arbitration proceedings shall be held in English and the seat and venue of the arbitration shall be Pune.</p>
<h4>NOTICES</h4>
<p>The Company may deliver notice to you under this Agreement by means of electronic mail, a general notice on the Company’s Platform, or by written communication to your address on record in the Company’s account information. You may give notice to the Company at any time via electronic mail to <a href='mailto:info@axonichealth.com'>info@axonichealth.com</a> or by letter delivered to the following address:
Office 403, Kapil Zenith IT Park, Bavdhan, Pune 411021</p>
<h4>CONTACT US</h4>
<p>If you have any questions about these Terms, please contact us by email to  <a href='mailto:info@axonichealth.com'>info@axonichealth.com</a></p>
<p>All rights reserved</p>
        </div>
    </div></>
  )
}

export default Term_conditions
