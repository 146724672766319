import React from "react";
import "./privacy.scss";
const Privacy = () => {
  return (
    <div className="privacy-parent parent">
      <div className="privacy-cont container">
        <h4>1. OVERVIEW</h4>
        <p>
          We believe in openness and privacy therefore at Axonic Health Private
          Limited, (“Company/we/our/us”), the User’s (“you/your”) privacy is of
          paramount importance, we respect your privacy and are committed to
          protecting it through our compliance with this policy which governs
          the use of our and our Mobile Application by the name AxonCare,
          developed by Axonic Health Private Limited, a corporation whose
          principal place of business is at 403, 4th Floor, Kapil Zenith IT
          Park, Bavdhan, Pune 411021, INDIA (“We/Our/Us/Company”) from time to
          time and services provided therein (“Platform “). This Privacy Policy
          is incorporated by reference into the Terms of Use of the Platform and
          shall be read along with the Terms of Use.
        </p>
        <p>
          This Privacy Policy explains our online and offline information
          practices, the kind of information we may collect, how we intend to
          use and share that information, and how you can opt-out of a use or
          correct or change such information. All capitalized terms not defined
          herein will have the meanings set forth in the Terms. Please take a
          moment to review this Privacy Policy, which governs the use of the
          Platform. By using our Platform, you agree to the Terms of Use,
          including definitions, and this Privacy Policy. If you do not agree
          with the terms of this Privacy Policy, please do not use this
          Platform.
        </p>
        <h4>2. SCOPE</h4>
        <p>
          This Privacy Policy applies to Personal Information that is collected
          by, processed by us in the course of our business, including on our
          Platform, www. .com (“Platform”), web applications (“App”), forums,
          blogs, and other online or offline offerings (collectively, the
          “Services”). All individuals whose responsibilities include the
          processing of personal information on behalf of the Company are
          expected to protect that data by adherence to this Privacy Policy.
        </p>
        <p>
          CHILDREN UNDER THE AGE OF 18 YEARS <br></br>
          Our Platform is not intended for children under 18 years of age. No
          one under age 18 may use or access the Platform. We do not knowingly
          collect personal information from children under 18 without parental
          consent. If we learn we have collected or received personal
          information from a child under 18 without verification of parental
          consent, we will delete, anonymize that information and terminate the
          user account. If you believe we might have any information from or
          about a child under 18, please contact us at{" "}
          <a href="mailto:info@axonichealth.com">info@axonichealth.com</a>
        </p>

        <h4>
          3.0 Transparency/Notice – Types of personal information we collect and
          how we use it
        </h4>
        <p>
          Some of the ways that the Company may collect personal information
          include:
        </p>
        <ul>
          <li>
            You may provide personal information directly to the Company through
            registering on our Platform, when you use the features on our
            Platform, during the course of providing services to you by us,
            interacting with the Services, participating in surveys, and
            requesting Services, or information and when you provide your
            personal information to us.
          </li>
          <li>
            As you navigate the Services, certain passive information may also
            be collected about your visit, including through cookies and similar
            technologies as described below.
          </li>
        </ul>
        <p>
          We endeavour to collect only that information which is relevant for
          the purposes of providing the Services to you. Below are the ways we
          collect personal information and how we use it.
        </p>
        <h4>3.1 TYPES OF PERSONAL INFORMATION WE COLLECT</h4>
        <p>
          Company collects Personal Information regarding its current,
          prospective, and former clients, customers, users, visitors, and
          guests (collectively “Individuals”).
        </p>
        <ul>
          <li>First and last name</li>
          <li>Birth date/age</li>
          <li>Gender</li>
          <li>PAN/Aadhar Number</li>
          <li>Race or colour</li>
          <li>Ancestry or national origin</li>
          <li>Religion or creed</li>
          <li>Mental or physical disability</li>
          <li>Sexual orientation</li>
          <li>Marital status</li>
          <li>
            Biometric data (fingerprints, faceprints, voiceprints, iris or
            retina scans)
          </li>
          <li>Address (including country and pin/postal code)</li>
          <li>Phone number/mobile number</li>
          <li>Email address</li>
          <li>Title and department</li>
          <li>Company name</li>
          <li>Technical information</li>
          <li>Your pictures or videos provided</li>
          <li>
            Professional contact details of employees and other individuals
            associated with our business
          </li>
          <li>Login ID and password</li>
          <li>
            User details provided at the time of registration or thereafter
          </li>
          <li>Records of interaction with Company representatives</li>
          <li>
            Your usage details (time, frequency, duration, pattern of use,
            features used, storage used)
          </li>
          <li>
            Master and transaction data and other data stored in your user
            account
          </li>
          <li>
            Internet Protocol address, browser type and version, browser
            language, referring URL, files accessed, errors generated, time
            zone, operating system, and platform
          </li>
          <li>
            Browser history, search history, information on interaction with a
            Platform, application, or advertisement
          </li>
          <li>Any other information willingly shared by you</li>
        </ul>
        <p>(collectively referred to as “Personal information”).</p>
        <ul>
          <li>
            <strong>Communications with us:</strong> We may collect personal
            information such as email address, phone number, mailing address,
            and any pictures or videos you may provide when you request
            information about our Services, register for our newsletter, request
            customer or technical support, or otherwise communicate with us.
          </li>

          <li>
            <strong>Surveys:</strong> From time to time, we may contact you to
            participate in online surveys. Participation is voluntary, and you
            may be asked to provide certain information, including Personal
            Information, to help us improve our products, sites, and/or
            services.
          </li>

          <li>
            <strong>Posting on the Services:</strong> Company may offer publicly
            accessible pages, blogs, private messages, or community forums. When
            you disclose information in these areas, the Services collect the
            information provided, including any Personal Information.
            Information submitted in public areas will be considered “public”
            and not subject to privacy protections.
          </li>

          <li>
            <strong>Automatic data collection:</strong> We may collect certain
            information automatically through our Services or other web analysis
            methods. This information includes Internet Protocol (IP) address,
            cookie identifiers, mobile carrier, mobile advertising identifiers,
            MAC address, IMEI, Advertiser ID, browser type and language,
            geo-location information, hardware type, operating system, Internet
            service provider, pages visited before and after using the Services,
            date and time of visit, time spent on each page, links clicked,
            pages viewed, and other actions taken on the Services.
          </li>

          <li>
            <strong>Cookies:</strong> We use cookies and web beacons to collect
            information. Cookies are small text files stored in visitors’ device
            browsers that remember preferences. Web beacons are electronic
            images used to help deliver cookies, count visits, and assess usage
            and campaign effectiveness. Information collected via cookies
            includes Personal Information, such as IP address, web browser,
            device type, and web pages visited before and after using the
            Platform, along with interactions on the Platform, including date
            and time of visit, and click metrics. Metrics may include user
            count, active usage, rebuild and image update frequency, editor
            usage, and resource allocation. Free users are automatically opted
            in for data collection, whereas paid users may opt out.
          </li>

          <li>
            <strong>Information submitted via services:</strong> Company may use
            content from any communications submitted via Services, including
            ideas, inventions, concepts, techniques, or know-how, for purposes
            such as developing, manufacturing, and marketing goods or services.
            Your name will only be released or publicized if: (a) you grant
            permission; (b) it’s necessary for the Services; or (c) required by
            law.
          </li>

          <li>
            <strong>Information from other sources:</strong> We may receive
            Personal Information from other sources, including third-party
            services, to supplement information provided by you. If you access
            our Services through a third-party application, we may collect
            information available publicly or through your privacy settings.
            This supplemental information helps us verify your information and
            improve our offerings. To stop this transmission, you may need to
            contact the third-party source directly.
          </li>
        </ul>
        <h4>3.2 How Company Uses Your Information</h4>
        <p>
          We acquire, hold, use, and process personal information about
          individuals for various business purposes, including:
        </p>
        <ul>
          <li>
            To provide products, services, or information requested by you.
          </li>
          <li>To operate and improve the Platform.</li>
          <li>
            To provide technical support, including diagnosing and resolving any
            issues you report.
          </li>
          <li>To enhance customer relationship management.</li>
          <li>
            To perform studies, research, and analysis for improving our
            information, analysis, services, and technologies.
          </li>
          <li>
            To ensure that content and advertising are customized to your
            interests and preferences.
          </li>
          <li>
            To contact you via phone, SMS, WhatsApp, email, etc., for
            appointments, technical issues, payment reminders, administrative
            purposes, account management, requested information, and security
            announcements.
          </li>
          <li>
            To send promotional mailings from us or our partners via SMS,
            WhatsApp, email, or postal mail.
          </li>
          <li>
            To advertise products and services of the Company and third parties.
          </li>
          <li>
            To transfer information about you if we are acquired by or merged
            with another company.
          </li>
          <li>
            To share information with our business partners for the provision of
            specific services you ordered to ensure effective service delivery.
          </li>
          <li>
            To fulfill our obligations in relation to any agreement you have
            with us.
          </li>
          <li>To build your profile on the Platform.</li>
          <li>
            To maintain and develop our business systems, including testing and
            upgrading these systems.
          </li>
          <li>To manage staff training and quality assurance.</li>
          <li>
            To respond to subpoenas, court orders, or legal processes, and to
            establish or exercise our legal rights or defend against legal
            claims.
          </li>
          <li>
            To investigate, prevent, or act against illegal activities,
            suspected fraud, Terms of Use violations, and breaches of
            agreements.
          </li>
          <li>
            To aggregate personal information for research, statistical
            analysis, and business intelligence and transfer such data in a
            de-identified form to third parties and affiliates.
          </li>
          <li>To manage individual information and accounts.</li>
          <li>To respond to questions, comments, and other requests.</li>
          <li>
            To assess vulnerabilities and other bugs found during your use of
            the Services.
          </li>
          <li>
            To provide access to certain areas, functionalities, and features of
            the Company’s Services.
          </li>
          <li>To contact you for customer support or technical support.</li>
          <li>To allow you to register for events.</li>
        </ul>

        <h4>Administrative Purposes</h4>
        <p>
          The Company may use personal information for its administrative
          purposes, including:
        </p>
        <ul>
          <li>Measuring interest in Company’s Services.</li>
          <li>Developing new products and services.</li>
          <li>Ensuring internal quality control.</li>
          <li>Verifying individual identity.</li>
          <li>
            Communicating about accounts and activities on Company’s Services
            and systems, and making changes to Company policy.
          </li>
          <li>
            Sending emails to verify accounts and for account management,
            customer service, or system maintenance.
          </li>
          <li>Processing payments for purchased products or services.</li>
          <li>Processing applications and transactions.</li>
          <li>Preventing prohibited or illegal activities.</li>
          <li>Enforcing our Terms.</li>
        </ul>

        <h4>Marketing Company Products and Services</h4>
        <p>
          The Company may use personal information to provide you with materials
          about offers, products, and services that may be of interest. Uses
          include:
        </p>
        <ul>
          <li>Tailoring content, advertisements, and offers.</li>
          <li>
            Notifying you about offers, products, and services that may be of
            interest.
          </li>
          <li>Providing Services to you and our sponsors.</li>
          <li>
            Other purposes disclosed at the time of providing personal
            information.
          </li>
          <li>Any other use with your consent.</li>
        </ul>
        <p>
          You may contact us at any time to opt out of using your personal
          information for marketing purposes.
        </p>

        <h4>Research and Development</h4>
        <p>
          The Company may use personal information alone or in combination with
          information from other sources to improve and develop products and
          Services.
        </p>

        <h4>Direct Mail, Email, and Outbound Telemarketing</h4>
        <p>
          Individuals may receive periodic communications from us with
          information on Company’s or our business partners’ products, services,
          or special offers. You can decline these communications at any time as
          described in Section 5.
        </p>

        <h4>Services via Mobile Devices</h4>
        <p>
          From time to time, the Company may provide Services specifically
          designed for mobile devices. Information such as device identifiers,
          location, mobile carrier, and operating system may be collected when
          using mobile versions of our Services. The information collected may
          be used to:
        </p>
        <ul>
          <li>Provide access to accounts.</li>
          <li>Track tool usage.</li>
          <li>Email reports and other information from the tool.</li>
          <li>Enhance tools and develop new ones.</li>
          <li>
            Improve quality and for other purposes as described in the Privacy
            Policy.
          </li>
        </ul>

        <h4>De-Identified and Aggregated Information Use</h4>
        <p>
          The Company may use de-identified and/or aggregated information for
          functions such as measuring interest and use of Services, research,
          internal analysis, analytics, and other legally permissible purposes.
        </p>

        <h4>Other Uses</h4>
        <p>
          The Company may use personal information for network and information
          security, direct marketing, disclosure to affiliated organizations,
          research (including marketing research), fraud prevention, or any
          other purpose disclosed at the time personal information is provided
          or with your consent.
        </p>

        <h4>Uses of Automatic Collection Technologies</h4>
        <p>We use cookies, web beacons, and similar technologies for:</p>
        <ul>
          <li>
            <strong>Operationally necessary</strong>: To access our sites,
            prevent fraudulent activity, improve security, and enable certain
            functions like shopping carts.
          </li>
          <li>
            <strong>Performance-related</strong>: To assess the performance of
            our Platforms and understand user interactions.
          </li>
          <li>
            <strong>Functionality-related</strong>: To offer enhanced
            functionality, like identifying you when you sign in or remembering
            preferences.
          </li>
          <li>
            <strong>Advertising or targeting-related</strong>: To deliver
            relevant ads on our sites or third-party sites based on your
            interests.
          </li>
        </ul>
        <p>
          You may opt out of these technologies by blocking, deleting, or
          disabling them as your device or browser permits.
        </p>

        <h4>3.3 Third-Party Platforms and Social Media Platforms</h4>
        <p>
          When logging in using social media, we receive information necessary
          to authenticate you, like your name, profile image, and email address.
          We use this information to create your account and communicate about
          requested information, products, and services.
        </p>

        <h4>3.4 Third-Party Payment Processing</h4>
        <p>
          If you use our Services for payments, we and third-party applications
          may collect certain financial information, including name, email,
          financial account details, and billing information to process
          transactions.
        </p>

        <h4>3.5 Incomplete Personal Information</h4>
        <p>
          If required personal information is not provided (e.g., in
          registration forms), we may be unable to process your application or
          provide requested products or services.
        </p>

        <h4>3.6 Sources of Collection of Personal Information</h4>
        <p>We collect personal information from:</p>
        <ul>
          <li>Your devices directly</li>
          <li>Other users of our Services</li>
          <li>Office visits</li>
          <li>Affiliates</li>
          <li>Government entities</li>
          <li>Consumer data resellers</li>
          <li>Business partners</li>
          <li>Publicly accessible sources</li>
        </ul>
        <h4>Information We Share</h4>
        <p>
          We may share your information as described in this Privacy Policy
          (e.g., with our third-party service providers; to comply with legal
          obligations; to protect and defend our rights and property) or with
          your permission.
        </p>

        <h4>Vendors and Service Providers</h4>
        <p>
          We may share any information we receive with vendors and service
          providers. These include providers for:
        </p>
        <ul>
          <li>Provision of IT and related services</li>
          <li>Provision of information and services you have requested</li>
          <li>Payment processing</li>
          <li>Customer service activities</li>
          <li>Other services related to the provision of the Services</li>
        </ul>
        <p>
          We have contracts with these providers that limit their use of
          personal information to only those purposes necessary to perform
          services on our behalf or comply with legal requirements.
        </p>

        <h4>4.0 Business Partners</h4>
        <p>
          We may share personal information with our business partners and
          affiliates for internal business purposes or to provide requested
          products or services. Business partners who collaborate with us will
          have their names appear alongside ours, and they are contractually
          obligated to maintain the confidentiality and security of the
          information they handle.
        </p>

        <h4>Content Visible to Others</h4>
        <p>
          When you create a profile or post content, this information may be
          visible to others. We are not responsible for the privacy practices of
          other users viewing and using this information.
        </p>

        <h4>
          Marketing – Interest-Based Advertising and Third-Party Marketing
        </h4>
        <p>
          We may allow third-party advertising partners to set tracking tools,
          like cookies, to collect information about your activities. We may
          share de-identified information and selected personal data (such as
          demographic information) with advertising partners, who may use it to
          deliver targeted ads on other platforms. This is known as
          “interest-based advertising.” You can opt-out of this at any time as
          explained in Section 5.
        </p>

        <h4>Disclosures to Protect Us or Others</h4>
        <p>
          We may access, preserve, and disclose your personal information,
          account information, and content if we believe it’s necessary to:
        </p>
        <ul>
          <li>
            Comply with legal or national security requests, such as court
            orders or subpoenas
          </li>
          <li>Respond to your requests</li>
          <li>Protect rights, property, or safety</li>
          <li>Enforce Company policies or contracts</li>
          <li>Collect amounts owed to us</li>
          <li>Prevent harm, fraud, or illegal activities</li>
        </ul>
        <p>
          Additionally, server logs may be reviewed periodically for security,
          and IP addresses may be shared with law enforcement for investigative
          purposes.
        </p>

        <h4>Merger, Sale, or Asset Transfers</h4>
        <p>
          If involved in a merger, acquisition, or other business transitions,
          your information may be transferred or sold as part of the
          transaction. We will seek to direct the recipient to use personal data
          consistently with this Privacy Policy.
        </p>

        <h4>International Data Transfers</h4>
        <p>
          We may transfer, process, access, and store personal information
          globally, including in the United States, the European Union, and
          other regions to provide our Services. Transfers comply with
          applicable legal requirements and are governed by adequate mechanisms.
          We use appropriate technical and organizational safeguards to protect
          your data. However, we cannot fully guarantee the security of data
          transmitted online; any such transmission is at your own risk.
        </p>
        <h4>5. Your Choices</h4>

        <h4>5.1 General</h4>
        <p>
          You may have the right to object to and opt out of certain uses and
          disclosures of your Personal Information. Where you have consented to
          Company’s processing of your Personal Information, you may withdraw
          that consent at any time. However, please note that withdrawal of
          consent does not affect the lawfulness of our processing activities
          based on consent prior to its withdrawal.
        </p>

        <h4>5.2 Email and Telephone Communications</h4>
        <p>
          If you receive an unwanted email from us, you can use the unsubscribe
          link at the bottom of the email to opt out of receiving future emails.
          We will process your request within a reasonable timeframe. Note:
        </p>
        <ul>
          <li>
            You will continue to receive transaction-related emails regarding
            products or services you have requested.
          </li>
          <li>
            We may also send you non-promotional communications regarding
            Company and our Services, which you cannot opt out of (e.g., updates
            to our Terms or this Privacy Policy).
          </li>
          <li>
            You will also receive necessary technical notices, updates, security
            alerts, and support and administrative messages.
          </li>
        </ul>
        <p>
          Please be aware that you cannot opt out of certain essential service
          messages, including necessary security alerts and legal notices.
        </p>

        <h4>5.3 Turning Off Cookies</h4>
        <p>
          You may erase or block cookies from your computer, except for
          functional cookies that are strictly necessary to make our Platform
          available to you. You can configure your browser to alert you when we
          attempt to send you a cookie, allowing you to accept or refuse it.
          Please note:
        </p>
        <ul>
          <li>
            Disabling cookies may prevent you from using certain Platform
            features and may affect functionality on the Platform.
          </li>
        </ul>

        <h4>6. Your Privacy Rights</h4>
        <p>
          We do not “sell” your Personal Information. In accordance with
          applicable law, you may have the right to, once a year and free of
          charge:
        </p>
        <ul>
          <li>
            Request confirmation of whether we are processing your Personal
            Information.
          </li>
          <li>Obtain access to or a copy of your Personal Information.</li>
          <li>
            Receive an electronic copy of Personal Information that you have
            provided to us, or ask us to send that information to another
            company (the “right of data portability”).
          </li>
          <li>Restrict our uses of your Personal Information.</li>
          <li>
            Seek correction or amendment of inaccurate, untrue, incomplete, or
            improperly processed Personal Information.
          </li>
          <li>Withdraw your consent.</li>
          <li>
            Request erasure of Personal Information held about you by us,
            subject to legal exceptions.
          </li>
        </ul>
        <p>
          Where permitted by law, you may use the contact methods set out in
          this Privacy Policy to exercise your rights in Personal Information.
          Please include your full name, the email associated with your Account,
          and a detailed description of your request. Requests will be processed
          in line with applicable laws.
        </p>
        <p>
          To protect your privacy, Company will take commercially reasonable
          steps to verify your identity before granting access or making changes
          to your Personal Information. In some cases, exercising these rights
          may not be possible, and we may continue to be authorized to process
          your data. To execute any of the rights mentioned, please contact us
          as outlined at the beginning of this document.
        </p>
        <h4>7. DATA RETENTION</h4>
        <p>
          Company retains the Personal Information we receive as described in
          this Privacy Policy for as long as you use our Services or as
          necessary to fulfil the purpose(s) for which it was collected, provide
          our Services, resolve disputes, establish legal defences, conduct
          audits, pursue legitimate business purposes, enforce our agreements,
          and comply with applicable laws. When determining the specific
          retention period, we take into account various criteria, such as the
          type of service provided to you, the nature and length of our
          relationship with you, and mandatory retention periods provided by law
          and the relevant statute of limitations. At the end of that retention
          period, your data will either be deleted completely or anonymized, for
          example by aggregation with other data so that it can be used in a
          non-identifiable way for statistical analysis and business planning.
        </p>
        <h4>8. SECURITY OF YOUR INFORMATION</h4>
        <p>
          The security of your Personal Information is important to us. We have
          adopted reasonable security practices and procedures including
          role-based access, password protection, encryption etc. to ensure that
          the Personal Information collected is secure. You agree that such
          measures are secured and adequate. We restrict access to your Personal
          Information to Our and Our affiliates’ employees, agents, third party
          service providers, partners, and agencies who need to know such
          Personal Information in relation to the Purposes as specified above in
          this Policy and provided that such entities agree to abide by this
          Privacy Policy. Despite our efforts and diligence, unfortunately, the
          Internet cannot be guaranteed to be 100% secure, and we cannot ensure
          or warrant the security of any information you provide to us. We do
          not accept liability for unintentional disclosure. We further disclaim
          all and any liability arising from false, misleading disclosures made
          by you. By using the Services or providing Personal Information to us,
          you agree that we may communicate with you electronically regarding
          security, privacy, and administrative issues relating to your use of
          the Services. If we learn of a security system’s breach, we may
          attempt to notify you electronically by posting a notice on the
          Services or sending an e-mail to you. You may have a legal right to
          receive this notice in writing.
        </p>
        <h4>9. CONTACT US</h4>
        <h4>9.1 COMPLAINTS</h4>
        <p>
          If you have any questions about our privacy practices or this Privacy
          Policy or if You need to update or correct your Personal Information
          or have any grievance with respect to the processing or use of your
          Personal Information, for any reason, you may send updates and
          corrections to us at ________________ and we will take all reasonable
          efforts to incorporate the changes within a reasonable period of time.
        </p>
        <h4>9.2 GRIEVANCE OFFICER</h4>
        <p>
          We have appointed a Grievance Officer to address any concerns or
          grievances that You may have regarding the processing of Your Personal
          Information. If you have any such grievances, please write to our
          Grievance Officer at _________[email ID], and our officer will attempt
          to resolve Your issues in a timely manner.
        </p>
        <h4>10. CHANGES TO OUR PRIVACY POLICY AND PRACTICES</h4>
        <p>
          Please note that some privacy rights and obligations may differ in
          certain locations based on local law, in which case We will comply
          with local legal requirements. We reserve the right, at any time, to
          modify this Privacy Policy. We will post a notification on our
          Platform every time the Privacy Policy is changed and ask that you
          agree to the updated Privacy Policy every time there is a change. Each
          version of this Privacy Policy will be identified at the top of the
          page by its effective date. You understand and agree that you will be
          deemed to have accepted the updated Privacy Policy if you use the
          Services after the updated Privacy Policy is posted on the Services.
          You are advised to go through the updates and if at any point you do
          not agree to any portion of the Privacy Policy then in effect, you
          must immediately stop using the Services.
        </p>
        <ul>
          <li>
            Revision to the Privacy Policy. We may revise this Privacy Policy in
            our sole discretion, so review it periodically. If you continue to
            visit this Site and use the services made available to you after
            such changes have been made, you hereby provide your consent to the
            changes.
          </li>
          <li>
            Posting of Revised Privacy Policy. If there are any material changes
            to this Privacy Policy, Company will notify you by email or as
            otherwise required by applicable law. We will post any adjustments
            to the Privacy Policy on this web page, and the revised version will
            be effective immediately when it is posted (or upon notice as
            applicable). If you are concerned about how your information is
            used, bookmark this page and read this Privacy Policy periodically.
          </li>
          <li>
            New Uses of Personal Information. Additionally, before we use
            Personal Information for any new purpose not originally authorized
            by you, we will endeavour to provide information regarding the new
            purpose and give you the opportunity to opt-out. Where consent of
            the Individual for the processing of Personal Information is
            otherwise required by law or contract, Company will endeavour to
            comply with the law or contract.
          </li>
        </ul>
        <p>
          You should review this Privacy Policy periodically so that you keep up
          to date on our most current policies and practices. You consent to any
          change or upon our posting of the new Privacy Policy on the Platform.
          Any interpretation associated with this Privacy Policy will be made by
          our legal counsel. This Privacy Policy includes examples but is not
          intended to be restricted in its application to such examples,
          therefore where the word “including” is used, it means “including
          without limitations”.
        </p>
        <h4>11. CONSENT</h4>
        <ul>
          <li>
            By clicking on the “I accept” button at the end of the page
            containing the Privacy Policy, Terms of Use, or by providing us with
            your Personal Information or using the facilities or Services
            provided by the Platform, you consent to the collection, storage,
            processing, disclosure, and transfer of your Personal Information in
            accordance with this Privacy Policy.
          </li>

          <li>
            You acknowledge that you are providing your Personal Information of
            your own free will.
          </li>

          <li>
            You acknowledge that documents and data uploaded by you to obtain
            our services and results are considered to be legally owned or
            permitted for your use. The Company is not responsible for any
            damages arising from such data provided by you.
          </li>

          <li>
            You acknowledge that the Company has no obligation to monitor
            documents, data, links, or other materials that you provide. You are
            responsible for evaluating all your data, including for accuracy,
            completeness, and reliability, and you assume all risks associated
            with using such data. The Company will not be liable for any damages
            incurred as a result of submitting or using such data.
          </li>

          <li>
            You acknowledge that we test and analyze certain new features with
            some users before rolling them out to all users.
          </li>

          <li>
            You acknowledge that any links, data, documents, or other materials
            provided by you do not contain illegal or pornographic content.
          </li>

          <li>
            You acknowledge that our Services are an automated tool; therefore,
            results depend on the quality of input provided by you. Thus, the
            Company shall not be held liable for the end result or the utility
            of the code/software autogenerated from our platform.
          </li>

          <li>
            If you are providing Personal Information on behalf of a third
            party, you confirm that you have the legal authority to do so and
            agree to this policy on behalf of such third party. If providing
            Personal Information on behalf of a minor, you confirm that you are
            the parent or legal guardian of the minor.
          </li>

          <li>
            You consent that the collection, storage, processing, disclosure,
            and transfer of any Personal Information will not cause any wrongful
            loss to you if conducted in accordance with this Privacy Policy.
          </li>

          <li>
            You have the option not to provide us with the requested Personal
            Information. You also have the option to withdraw your consent at
            any time, provided such withdrawal is communicated to us in writing.
            (If you wish to withdraw your consent, please click here.) If you do
            not provide Personal Information or withdraw consent at any time, we
            may choose not to fulfill the purposes for which the information was
            sought and may restrict your access to the Platform or Services.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Privacy;
